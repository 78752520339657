import {createBrowserRouter,createRoutesFromElements,RouterProvider,Route} from "react-router-dom";
import './App.css';
import { Joinourteam } from "./components/Joinourteam";
import { Ourservices } from "./components/Ourservices";
import { Privacy } from "./components/Privacy";
import { Home } from "./components/Home";
import { Routelayout } from "./components/Routelayout";
import { Apply } from "./components/Apply";
import { Successfully } from "./components/Successfully";


function App() {
  const router = createBrowserRouter(createRoutesFromElements(
    <Route path="/" element={<Routelayout/>}>
      <Route index element={<Home />}/>
      <Route path="/join" element={<Joinourteam />}/>
      <Route path="/services" element={<Ourservices/>}/>
      <Route path="/privacy" element={<Privacy/>}/>
      <Route path="/apply" element={<Apply/>}/>
      <Route path="/submit" element={<Successfully/>}/>
      
    </Route>
  ))
  return (
    <div className="App">
      <RouterProvider router={router}/>
    </div>
  );
}

export default App;
