import React, { useState } from 'react';
import {Form,Button} from "react-bootstrap";
import axios from "axios";
import { useNavigate } from 'react-router-dom';


export const Apply = () => {
  const [values,setValues] = useState({name:'',address:'',email:''});
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post("/api/apply_services", values)
    .then((res) => {
      navigate("/submit")
      console.log(res)
    })

    .catch((err) => console.log(err))};
    localStorage.setItem('name', values.name);
    

  

  return (
    <div className='container'>
      <h4>APPLY FOR HOMECARE SERVICES</h4>
      <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
      <Form.Label>Full Name</Form.Label>
      <Form.Control type="text" placeholder="full name" name='name' 
       onChange={(e)=>setValues({...values,name:e.target.value})} />
      <Form.Label>Address</Form.Label>
        <Form.Control type="text" placeholder="address" name='address' 
         onChange={(e)=>setValues({...values,address:e.target.value})} />
      <Form.Label>Email address</Form.Label>
      <Form.Control type="email" placeholder="name@example.com" name='email' 
       onChange={(e)=>setValues({...values,email:e.target.value})} />
      <Button className='btn btn-dark w-100 mt-4' type='submit'>Submit</Button>
      </Form.Group>
    </Form>

    </div>
  )
}
