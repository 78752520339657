import React from 'react';

export const Successfully = () => {
  const name = localStorage.getItem('name');
  return (
    <div className='container'>
    Dear {name},

Thank you for submiting. We got your request and within 2 business days, 
we will get in touch.




Best regards, HNur Homecare
    </div>

  )
}
