import React from 'react'

export const Footer = () => {
  return (
    <>
    <footer class="footer mt-auto py-3 bg-dark container">
  <div class="container">
    <span class="text-white">&copy; Hnur Homecare</span>
  </div>
</footer>
    </>
  )
}
