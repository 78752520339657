import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {Link} from "react-router-dom";


export const Navbarpanel = () => {
  return (
    <>
   <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand to="/" as={Link}>Hnur Homecare</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav justify-content-end">
          <Nav className="me-auto">
          <Nav.Link to="/" as={Link}>HOME</Nav.Link>
            <Nav.Link to="/services" as={Link}>OUR SERVICES</Nav.Link>
            <Nav.Link to="/join" as={Link}>JOIN OUR TEAM</Nav.Link>
            <Nav.Link to="/privacy" as={Link}>PRIVACY</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    
    </>
  )
}
